:root {
    --jsyellow: #fbf100;
    --jsblue: #09c;
    --jsbluelight: #8ee3ff;
    --jsbluedark: #d69e2e;
    --jspurple: #cc66cc;
    --jsyellownew: #ffcd1e;
    --jsyellowlight: rgba(255,205,30,0.75);
    --jsyellowdark: #d69e2e;
    --jsgrey: #e9e9e9;
    --jsgreydark: rgba(211, 211, 211, 1);

    --grey100: #fdfdfd;
    --grey700: #444;

    --black: #000;

    --shadowLight: 0 2px 5px rgba(0,0,0,0.2), -10px -10px 20px rgba(0,0,0,0.05);
    --shadow: 0 3px 7px rgba(0,0,0,0.4), -10px -10px 20px rgba(0,0,0,0.05);
    --shadowHover: 0 3px 10px rgba(0,0,0,0.5), -10px -10px 20px rgba(0,0,0,0.05);
}

