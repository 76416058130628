.wrapper {
  margin-bottom: 2rem;
  padding: 3rem;
}

.iconWrapper {
  height: 2rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--jsgreydark);
  border-radius: 1000px;
  color: #000;

  &.accomplished {
    color: #fff;
    background-color: var(--jsyellownew);
  }
}


.progressPoint {
  width: 100%;
}

.heading {
  position: absolute;
  font-size: 1rem;
  font-weight: normal;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);

  &.moblie {

    @media only screen and (max-width: 750px){
      display: none;

      &.active {
        display: block;
      }
    }
  }
}