.multi-select {
  .dropdown {
    .dropdown-heading {
      width: 100%;
      background-color: #fdfdfd !important;
      height: 50px !important;
      color: #111;
      border: 1px solid #ccc;
      margin: 0.5rem 0 1rem;
      box-sizing: border-box;
      border-radius: 2px !important;

      .dropdown-heading-value {
        display: flex;
        justify-items: flex-start;
        align-items: center;
        padding-left: 1rem !important;
      }
    }

    .dropdown-content {
      .select-item {
        text-align: left;
      }
    }
  }
}