.form {
  margin-bottom: 2rem;
  height: max-content;
  transition: all 0.5s;

}

.formClosed {
  height: 0;
  overflow: hidden;
  padding: 0;
}

.formContainer {
  position: relative;
  min-height: 4rem;
  margin-bottom: 1rem;
  background-color: #fdfdfd;
}

@media print {
  /*html, body {
    height: 100vh;
    margin: 0 !important;
    padding: 0 !important;
    page-break-after: avoid;
    page-break-before: avoid;
  }*/
  .formContainer {
    visibility: hidden;
  }
}

.toggleFormBtnContainer {

}

.toggleButton {
  width: 100%;
  background-color: var(--jsyellownew);
  padding: 1rem;
  //text-transform: uppercase;
  font-size: 1.2rem;
  border-radius: 2px;
  color: #000;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-between;
  font-family: -apple-system, BlinkMacSystemFont, 'Inter','Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-weight: bold;

  &:hover {
    background-color: var(--jsyellowdark);
  }
}

.textInput {
  width: 100%;
  background-color: #fdfdfd;
  padding: 1rem;
  color: #111;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin: 0.5rem 0 1rem;
  box-sizing: border-box;
  max-height: 50px;
  resize: none;
  &.error {
    border-color: red;
  }
}

.errorMessage {
  color: red;
  font-size: 0.8rem;
  text-align: left;
  position: absolute;
  width: 100%;
  left: 0.5rem;
  bottom: 0;
}


.infoErrorMessage {
  color: black;
  font-size: 0.8rem;
  text-align: left;
  position: absolute;
  width: 100%;
  left: 0.5rem;
  bottom: 0;
}

.textLabel {
  text-align: left;
  display: block;
}

.checkboxLabel {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 95%;
    background-color: var(--jsyellownew);
    z-index: -1;
    transition: all 0.2s;
    transform: scaleX(0);
  }
}

.checkbox {
  &:checked {
    & + .checkboxLabel::after {
      transform: scaleX(1);
    }
  }
}

.dropDown {
  width: 100%;
  background-color: var(--grey100);
  padding: 1rem;
  color: #111;
  border: 1px solid #ccc;
  border-radius: 2px;
  margin: 0.5rem 0 1rem;
  box-sizing: border-box;
}

.btn {
  background-color: var(--jsyellownew);
  padding: 1rem;
  border-radius: 2px;
  color: #000;
  cursor: pointer;
  transition: all 0.2s;
  border: none;
  margin-right: 1rem;

  &:hover {
    background-color: var(--jsyellowdark);
  }

  &:disabled {
    background-color: var(--jsgrey);
    cursor: not-allowed;
  }

  &.btnYellow {
    background-color: var(--jsyellownew);
    color: #111111;

    &:hover {
      background-color: var(--jsyellowdark);
    }

    &:disabled {
      background-color: var(--jsgrey);
      color: #999;
      cursor: not-allowed;
    }
  }
}

.btnSorting {

  border: none;
  background-color: var(--jsgreydark);
  color: #ffffff;
  padding: 5px 10px;
  height: 35px;
  margin: 1px;
  cursor: pointer;


  &.active, &:hover {
    background-color: var(--jsyellownew);
  }
}

.dropdownPerPage {
  height: 35px;
  background-color: var(--grey100);
  border: 1px solid #ccc;
}

.btnDelete {
  border-radius: 2px;
  border: none;
  background-color: red;
  height: 50px;
  width: 50px;
  margin: 0.5rem 0 1rem;
  transition: all 0.2s;

  &:hover {
    background-color: #ff4d4a;
  }

  &:disabled {
    background-color: #ffa4a5;
  }
}

.btnWrapper {
  width: 100%;
  display: flex;
  justify-items: end;
  padding: 0.5rem;
}

.multiCol {
  column-count: 3;
  column-width: 200px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.multiInputContainer {
  background-color: #fdfdfd;
  border: 1px solid #ccc;
  padding: 1rem;
  margin-bottom: 1rem;
}

.infoButton {
  height: 25px;
  width: 25px;
  font-size: 11px;
  background-color: var(--jsyellownew);
  border-radius: 100px;
  position: absolute;
  top: 50%;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-65%);
}

.infoButtonMinStyle {
  border-radius: 1000px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationDropdownContainer {
  display: flex;
  flex-direction: column;
  justify-items: left;
  align-items: stretch;

  margin-top: -10px;

  position: absolute;
  z-index: 1;
  background-color: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 5px;
}

.locationSuggestion {
  padding: 10px 15px;
}