.nav {
  display: flex;
  justify-content: space-around;
  max-width: 1000px;
  margin: auto;
  box-shadow: var(--shadowLight);
}

.link {
  padding: 1rem;
  text-decoration: none;
  color: var(--black);
  text-transform: uppercase;
  font-weight: 500;
  transition: all 0.2s;

  &:hover {
      background-color: var(--black);
      color: var(--jsyellow);
  }
}

.logo {
  width: 240px;
  margin-bottom: 1rem;
}

