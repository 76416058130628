.container {
  position: relative;
  padding: 0.5rem 0;

  .card {
    position: relative;
    height: 200px;
    overflow: hidden;
    box-shadow: var(--shadowLight);
    border-left: 10px solid var(--jsyellownew);
    text-overflow: ellipsis;
    transition: all 0.4s;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 1000px;
      height: 100px;
      background: linear-gradient(to top, #fff 15%, transparent);
    }

    &:hover {
      box-shadow: var(--shadow);
    }

    h3 {
      word-break: break-word;
      text-align: left;
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}

.previewContainer {
  display: grid;
  grid-template-columns: minmax(10px, 1fr);
}

.heading {
  line-height: 15px;
}

.headingOrt {
  line-height: 5px;
  color: var(--grey700);
  font-size: 1rem;
}

.sortingContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 1rem;

  .pages {
    margin-right: auto;
  }
}

.ortDate {
  display: flex;
  justify-content: space-between;
}